<template>
  <ion-page>
    <ion-content fullscreen>
      <List
        ref="board"
        :datas="boardList"
        :total="total"
        :next-loading="disabled"
      >
        <template  #regDate="{ item }">
          {{ $moment(item.reg_date).format('YYYY.MM.DD HH:mm:ss') }}
        </template>
        <template  #rightButton="{ item }">
          
            <ion-button size="" @click="handleQrcodeModal(item)" color="secondary">{{$t('buttonLabelQr2')}}</ion-button> 
            
            <template v-if="item.hardware_inspection_num === 0 || item.hardware_inspection_num === ''">
              <ion-button size="" :disabled="true" color="medium">{{$t('buttonLabelExterior')}}</ion-button>
            </template>
            <template v-else-if="item.hardware_inspection_num > 0 ">
              <template v-if="item.save_check">
                <ion-button size="" @click="handleCheckResult(item)" color="danger">{{$t('buttonLabelExterior')}}</ion-button>
              </template>
              <template v-else>
                <ion-button size="" @click="handleCheckResult(item)" color="tertiary">{{$t('buttonLabelExterior')}}</ion-button>
              </template>              
            </template>

            
        </template>
      </List>
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button @click="handleNewOrder" color="tertiary">
          <ion-icon :icon="addOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed" >
        <ion-fab-button @click="handleRefresh($event)" color="tertiary">
          <ion-icon :icon="reloadOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-modal id="qr-modal" :is-open="isOpenQrModal" @willDismiss="onWillDismiss">
          <vue-qrcode :value=qrCodeData :options="{ width: 200 }"></vue-qrcode>
      </ion-modal>

      <ion-loading :is-open="isOpenLoading" message="Loading..." spinner="circles"></ion-loading>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonModal,
  IonPage,
  IonContent, 
  IonButton,
  IonFab,
  IonFabButton,
  IonIcon,
  useIonRouter,
  IonLoading
} from '@ionic/vue'
import { addOutline, reloadOutline } from 'ionicons/icons'
import { defineComponent, ref, watch, onMounted,  nextTick } from 'vue'
import { useRoute } from 'vue-router'
//import { useI18n } from 'vue-i18n';
import List from '@/components/List'
import { addData, listData } from '@/api/board/common'
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default defineComponent({
  name: 'mobile-checkup',
  components: {
    IonModal,
    IonPage,
    IonContent,
    IonButton,
    IonFab,
    IonFabButton,
    IonIcon,
    VueQrcode,
    List,
    IonLoading 
  },
  setup() {
    //const { t } = useI18n({ useScope: 'global' });
  
    const route = useRoute()
    const router = useIonRouter()
    const board = ref(null)

    let qrCodeData = ref()
    let isOpenQrModal = ref(false)
    
    let total = ref(0)
    let currentPageNum = ref(1)
    let boardList = ref([])
    let nextLoading = ref(true)
    let isOpenLoading = ref(true)


    watch(
      () => route.params,
      async () => {
        await nextTick(() => {        
          if(route.path == '/mobile/checkup'){
            getList()
          }
        })
      },
      {
        immediate: true
      }
    )

    onMounted(async () => {
      //await getList()
    })

    const getList = async () => {
      if (nextLoading.value) {      
        const memNum = Cookies.get('memNum')
        const apiUrl = `/api/cart/`+Number(memNum)
        const res = await listData(apiUrl)

        const datas = res.map((o) => {

          
          const save_check = Cookies.get(o.ord_number) === undefined ? false : true
          return {
            id: o.cart_num,
            title: o.ord_number,
            reg_date: o.reg_date,
            hardware_inspection_num: o.hardware_inspection_num,
            save_check: save_check
          }
        })

        total.value = datas.length
        boardList.value = datas
        isOpenLoading.value = false
      }
    }

    const postOrder = async () => {
      const memNum = Cookies.get('memNum')
      const apiUrl = '/api/cart'
      
      const payload = {
        cartInsert: {
          pro_num : 281864, //값 고정
          mem_num: Number(memNum),
          prod_num : 0
        },

      }
      await addData(apiUrl, payload).then(response => {
        if(response.ord_number !== ''){
          getList()
        }
      }).catch(e => {
        console.log(e)
      })
    }

    const handleQrcodeModal = (item) => {
      //qr 셋팅
      qrCodeData.value = item.title
      //팝업 호출
      isOpenQrModal.value = true
    }
    
    const onWillDismiss = () => {
      //qr modal close 감지
      isOpenQrModal.value = false
    };
 

    const handleCheckResult = (item) => {
      router.push({
        name: 'mobile-survey',          
        params: {
          num: item.title
        }
      })
    }

    const handleNewOrder = () => {
      postOrder()
    }

    const handleRefresh = () => {
      location.reload()
    }

    return {      
      isOpenLoading,
      qrCodeData,
      isOpenQrModal,
      onWillDismiss,
      board,
      boardList,
      addOutline,
      reloadOutline,
      total,
      currentPageNum,
      handleQrcodeModal,
      handleCheckResult,
      handleNewOrder,
      handleRefresh
    }
  }
})
</script>

<style scoped>
:deep ion-avatar {
  --border-radius: 2px;
}

:deep ion-item .item-native {
  --padding-start: 0;
}

ion-modal#qr-modal {
  --width: fit-content;
  --min-width: 200px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}


ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-button {
  font-size: 12px;
}
</style>
